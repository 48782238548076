<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div>
    <div v-for="(iconAction, i) in iconsAction" :key="i" class="inline">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-img
            :id="`iiotNodeListTableAction${iconAction}Image_${index}`"
            :src="`/img/${iconAction}.svg`"
            v-bind="attrs"
            max-height="40px"
            max-width="40px"
            v-on="on"
            @click.native.stop="clickAction()"
          />
        </template>
        <span>{{ $t(`defaultTable.${iconAction}`) }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iconsAction: {
      type: Array,
      default: () => [],
    },
    fetchAction: {
      type: Function,
      default: () => {},
    },
    tableItem: {
      type: Object,
      default: () => ({
        deployed: {},
      }),
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    clickAction() {
      this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
        title: this.$t('nodes.list.deleteConfirm'),
        subTitle: this.$t('nodes.list.removeSubTitle', {
          name: this.tableItem.name,
          serialNumber: this.tableItem.serialNumber,
        }),
        callback: async () => {
          try {
            await this.$store.dispatch('nodes/remove', this.tableItem);
            this.action = 'delete';
            this.fetchAction()(this.action);
          } catch (e) {
            this.$log.debug(e);
          }
        },
      });
    },
  },
};
</script>

<style scoped>
.inline {
  display: inline-block;
}
</style>
